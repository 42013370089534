import styled from 'styled-components'

export const Carousel = styled.div`
    position: relative;
`

export const Pagination = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 70px;
    margin-top: 4rem;
    .swiper-pagination-bullet {
        position: relative;
        width: 18px;
        height: 18px;
        border: 1px solid var(--color-dark);
        cursor: pointer;
        background: var(--color-light);
        opacity: 1;
        &:not(:last-of-type)::after {
            position: absolute;
            right: -60px;
            top: 8.5px;
            display: block;
            content: "";
            width: 45.5px;
            height: 1px;
            background: var(--color-dark);
        }
    }
    .swiper-pagination-bullet-active {
        background: var(--color-dark);
    }
`