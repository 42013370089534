import styled from 'styled-components'

// COMPONENTS
import { Section } from '../../components/section/Section.styles'
import { Wrapper } from '../../components/wrapper/Wrapper.styles'
import { Text } from '../../components/text/Text.styles'
import { Button } from '../../components/button/Button.styles'

export const SectionContainer = styled(Section)`
    min-height: auto;
    background-color: var(--color-2);
    color: var(--color-ligth);
`;

export const SectionWrapper = styled(Wrapper)`
    padding: 8.25rem 3.75rem 10.125rem;
    @media screen and (max-width: 1118px) {
        padding: var(--padding);
    }
`;

export const Content = styled.div`
    display: flex;
    color: var(--color-warm);
    max-width: 1118px;
    width: 100%;
    padding: 0 7rem;
    @media screen and (max-width: 1118px) {
        padding: 0;
    }
`;

export const ContentBody = styled.div`
    @media screen and (max-width: 1118px) {
        .btn-modal {
            font-size: 0.563rem;
            line-height: 0.813rem;
        }
    }
`;

export const ContentText = styled(Text)`
    margin: 0 0 2rem;
    @media screen and (max-width: 1118px) {
        font-size: 0.938rem;
        line-height: 1.375rem;
    }
`;

export const ContentButton = styled(Button)`
    @media screen and (max-width: 1118px) {
        font-size: 0.563rem;
        line-height: 0.813rem;
    }
`;

export const ArrowRight = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin-right: 90px;
    @media screen and (max-width: 1118px) {
        margin-right: 50px;
    }
    svg {
        margin-bottom: 10.5px;
        @media screen and (max-width: 1118px) {
            width: 12.02px;
            height: 12.02px;
        }
    }
`;