import { ReactComponent as NaturaLogo } from '../assets/images/brands/natura-logo.svg'
import { ReactComponent as NaturaLogoWhite } from '../assets/images/brands/natura-logo-white.svg'
import naturaBackground from '../assets/images/brands/natura-image.jpg'
import naturaBackgroundMobile from '../assets/images/brands/natura-image-mobile.jpg'
import { ReactComponent as NubankLogo } from '../assets/images/brands/nubank-logo.svg'
import { ReactComponent as NubankLogoWhite } from '../assets/images/brands/nubank-logo-white.svg'
import nubankBackground from '../assets/images/brands/nubank-image.jpg'
import nubankBackgroundMobile from '../assets/images/brands/nubank-image-mobile.jpg'
import { ReactComponent as NetflixLogo } from '../assets/images/brands/netflix-logo.svg'
import { ReactComponent as NetflixLogoWhite } from '../assets/images/brands/netflix-logo-white.svg'
import netflixBackground from '../assets/images/brands/netflix-image.jpg'
import netflixBackgroundMobile from '../assets/images/brands/netflix-image-mobile.jpg'
import { ReactComponent as MagaluLogo } from '../assets/images/brands/magalu-logo.svg'
import { ReactComponent as MagaluLogoWhite } from '../assets/images/brands/magalu-logo-white.svg'
import magaluBackground from '../assets/images/brands/magalu-image.jpg'
import magaluBackgroundMobile from '../assets/images/brands/magalu-image-mobile.jpg'
import { ReactComponent as IfoodLogo } from '../assets/images/brands/ifood-logo.svg'
import { ReactComponent as IfoodLogoWhite } from '../assets/images/brands/ifood-logo-white.svg'
import ifoodBackground from '../assets/images/brands/ifood-image.jpg'
import ifoodBackgroundMobile from '../assets/images/brands/ifood-image-mobile.jpg'

const brands = [
    {
        about: "A Natura se protegeu do impacto negativo da pandemia com um portfolio diversificado, alto investimento em inovação – índice de 67,1%, o maior desde 2015 – e aceleração da transformação digital aplicada às consultoras, lojas físicas, e-commerce e social selling.",
        background: naturaBackground,
        backgroundMobile: naturaBackgroundMobile,
        color: "#FF6C0E",
        logo: <NaturaLogo />,
        logoWhite: <NaturaLogoWhite />,
        name: "NATURA",
        position: 1,
        score: "809.5",
        slug: "natura"
    },
    {
        about: "Na contramão do que as empresas tradicionais do setor construíram ao longo dos anos, Nubank cria experiências engajadoras, senso de pertencimento, vínculos emocionais e relações que melhoram a vida das pessoas.",
        background: nubankBackground,
        backgroundMobile: nubankBackgroundMobile,
        color: "#820AD1",
        logo: <NubankLogo />,
        logoWhite: <NubankLogoWhite />,
        name: "NUBANK",
        position: 2,
        score: "786.3",
        slug: "nubank"
    },
    {
        about: "Guiada pela missão de entreter o mundo, a Netflix une descomplicação e ampla oferta de entretenimento à distância de apenas um “tudum”.<br />Essa conveniência norteia toda a experiência da marca: a facilidade da contratação e do cancelamento, um eficiente uso de dados para criar uma navegação personalizada e a criação de produções com temas ancorados na contemporaneidade e que fazem parte da realidade do seu público. ",
        background: netflixBackground,
        backgroundMobile: netflixBackgroundMobile,
        color: "#D81F26",
        logo: <NetflixLogo />,
        logoWhite: <NetflixLogoWhite />,
        name: "NETFLIX",
        position: 3,
        score: "760.0",
        slug: "netflix"
    },
    {
        about: "Uma plataforma digital com pontos físicos de calor humano: é assim que Frederico Trajano, CEO do Magalu, define a marca. A máxima não poderia ser mais atual, principalmente no contexto da pandemia, quando a companhia manteve uma agenda positiva para minimizar os impactos da COVID-19.",
        background: magaluBackground,
        backgroundMobile: magaluBackgroundMobile,
        color: "#0F88FF",
        logo: <MagaluLogo />,
        logoWhite: <MagaluLogoWhite />,
        name: "MAGALU",
        position: 4,
        score: "733.2",
        slug: "magalu"
    },
    {
        about: "Ganhou uma nova importância para a vida dos brasileiros a partir de 2020 e, desde então, vem redefinindo a categoria. Atualmente, a marca está expandindo sua atuação para tornar-se uma plataforma de facilidade e conveniência, integrando diferentes elos da cadeia varejista. Além disso, ampliou sua atuação junto a pequenos negócios e passou a oferecer empréstimos para restaurantes parceiros.",
        background: ifoodBackground,
        backgroundMobile: ifoodBackgroundMobile,
        color: "#EA1D2C",
        logo: <IfoodLogo />,
        logoWhite: <IfoodLogoWhite />,
        name: "IFOOD",
        position: 5,
        score: "728.8",
        slug: "ifood"
    }
]

export default brands