import styled from 'styled-components'

// COMPONENTS
import { Section } from '../../../components/section/Section.styles'
import { Wrapper } from '../../../components/wrapper/Wrapper.styles'
import { Text } from '../../../components/text/Text.styles'

export const SectionContainer = styled(Section)`
    flex-direction: column;
`;

export const SectionWrapper = styled(Wrapper)`
    justify-content: center;
    padding-top: 0;
`;

export const Content = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    min-height: 600px;
    height: 100%;
    border-top: 2px solid var(--color-dark);
    flex-grow: 1;
`;

export const ContentTitle = styled(Text)`
    width: 100%;
    font-size: 1.125rem;
    opacity: 0.4;
    padding: 23px 0;
    margin-bottom: 4rem;
    @media screen and (max-width: 1118px) {
        font-size: 0.938rem;
        line-height: 1.375rem;
    }
`;

export const ContentBody = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 0 7rem;
    flex: 1;
    gap: 50px;
    overflow: hidden;
    @media screen and (max-width: 1118px) {
        flex-direction: column;
        padding: 0;
    }
`;

export const ContentText = styled(Text)`
    font-size: 1.5rem;
    width: 423px;
    @media screen and (max-width: 1118px) {
        font-size: 0.938rem;
        line-height: 1.375rem;
        width: 100%;
    }
`;

export const ContentChart = styled.div`
    flex: 1;
    height: 100%;
    min-height: 593px;
    min-width: 637px;
    width: 100%;
    @media screen and (max-width: 1118px) {
        width: 100vw;
        position: relative;
        margin-left: -85vw;
        left: 50%;
        min-height: 460px;
        .apexcharts-xaxis {
            text {
                font-size: 9px;
            }
        }
    }
    .apexcharts-xaxis {
        .apexcharts-datalabel {
            &:first-of-type {
                transform: translateY(-10px);
            }
        }
    }
`;

export const ContentRank = styled.div`
    position: relative;
    width: 7rem;
    height: 100%;
    @media screen and (max-width: 1118px) {
        display: none;
    }
`;