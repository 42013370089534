import styled from 'styled-components'

// COMPONENTS
import { Section } from '../../components/section/Section.styles'
import { Wrapper } from '../../components/wrapper/Wrapper.styles'

export const SectionContainer = styled(Section)`
    position: fixed;
    top: 0;
    min-height: auto;
    height: auto;
    background-color: transparent;
    color: var(--color-light);
    z-index: 99;
    transition: 0.15s all ease;
    .logo-white {
        display: inline-block;
    }
    .logo-black {
        display: none;
    }
    &:not(.active,.open).dark-header {
        .logo-white {
            display: none;
        }
        .logo-black {
            display: inline-block;
        }
        /* svg {
            path {
                fill: var(--color-dark);
            }
        } */
        button {
            div {
                background-color: var(--color-dark);
            }
        }
    }
    
    &.scrolled,
    &.active {
        background-color: var(--color-dark);
        .wrapper {
            padding: 1rem 3.75rem;
            @media screen and (max-width: 1118px) {
                padding: 0.75rem 1.875rem;
            }

            .logo-white {
                display: inline-block;
            }
            .logo-black {
                display: none;
            }
        }
    }
`;

export const SectionWrapper = styled(Wrapper)`
    align-items: center;
    justify-content: space-between;
    transition: 0.15s all ease;
    .logo {
        z-index: 9999;
        img, 
        svg {
            max-width: 202px;
            width: auto;
            height: auto;
            @media screen and (max-width: 1118px) {
                max-width: 138px;
            }
        }
    }
`;


