import React from 'react'

// ASSETS
import backgroundWrapper from '../../../assets/images/background-highlights.jpg'

// STYLES
import * as Styled from './Highlights.styles'

// DATA
import data from '../../../data/home/highlights'

const Highlights = () => {
    return (
        <Styled.SectionContainer id="highlights">
            <Styled.SectionWrapper>
                <Styled.Content>
                    <Styled.ContentTitle
                        colorName="--color-light"
                        lineHeight="10.875rem"
                        fontSize="9rem"
                        dangerouslySetInnerHTML={{ __html: data.title }}
                    />
                    <Styled.ContentTextWrapper className="textWrapper">
                        <Styled.ContentTextOne
                            dangerouslySetInnerHTML={{ __html: data.textOne }}
                        />
                    </Styled.ContentTextWrapper>
                </Styled.Content>
            </Styled.SectionWrapper>
            <Styled.SectionWrapper backgroundImage={backgroundWrapper}>
                <Styled.Content>
                    <Styled.ContentTextWrapper className="textWrapper">
                        <Styled.ContentTextTwo
                            dangerouslySetInnerHTML={{ __html: data.textTwo }}
                        />
                    </Styled.ContentTextWrapper>
                </Styled.Content>
            </Styled.SectionWrapper>
            <Styled.SectionWrapper>
                <Styled.Content>
                    <Styled.ContentTextWrapper className="textWrapper">
                        <Styled.ContentTextThree
                            dangerouslySetInnerHTML={{ __html: data.textThree }}
                        />
                    </Styled.ContentTextWrapper>
                </Styled.Content>
            </Styled.SectionWrapper>
        </Styled.SectionContainer>
    );
}

export default Highlights
