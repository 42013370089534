import styled from 'styled-components'

// COMPONENTS
import { Section } from '../../../components/section/Section.styles'
import { Wrapper } from '../../../components/wrapper/Wrapper.styles'
import { Title } from '../../../components/title/Title.styles'
import { Text } from '../../../components/text/Text.styles'

export const SectionContainer = styled(Section)`
    background-color: var(--color-dark);
`;

export const SectionWrapper = styled(Wrapper)`
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 8.125rem 3.75rem 0;
    @media screen and (max-width: 1118px) {
        padding: var(--padding);
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--color-light);
    max-width: 1118px;
    width: 100%;
    height: 100%;
    @media screen and (max-width: 1118px) {
        align-items: flex-start;
    }
`;

export const ContentTitle = styled(Title)`
    max-width: 520px;
    width: 100%;
    margin-bottom: 3.5rem;
    margin-left: 15%;
    @media screen and (max-width: 1118px) {
        font-size: 2.125rem;
        line-height: 2.563rem;
        max-width: 189px;
        margin-left: 60px;
    }
`;

export const ContentText = styled(Text)`
    margin-left: 45%;
    max-width: 448px;
    @media screen and (max-width: 1118px) {
        font-size: 0.75rem;
        line-height: 1.125rem;
        margin-left: 60px;
    }
`;

export const Brands = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
    padding-bottom: 2.5rem;
    margin-top: -128px;
    @media screen and (max-width: 1118px) {
        margin-top: 0;
        flex-direction: column;
        align-items: center;
    }
`;

export const Date = styled.div`
    font-family: PoppinsMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: var(--color-light);
    white-space: nowrap;
    transform: rotate(90deg) translateX(28px);
    position: absolute;
    right: -22px;
    top: -90px;
    opacity: 0.35;
    @media screen and (max-width: 1118px) {
        display: none;
    }
`;

export const Brand = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    width: 100%;
    @media screen and (min-width: 1118px) {
        width: auto;
        &:not(:last-of-type) {
            border-right: 2px solid var(--color-light);
        }
        &:nth-of-type(1),
        &:nth-of-type(2) {
            min-height: 1143px;
            .dateArea {
                right: -45px;
                top: -15px;
            }
        }
    }
`;

export const BrandContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 267px;
    min-height: 772px;
    padding: 1.75rem;
    transition: width 300ms;
    cursor: pointer;
    z-index: 1;
    @media screen and (max-width: 1118px) {
        padding: 0 1.875rem;
        flex-direction: row;
        align-items: stretch;
        min-height: 80px;
        width: 100%;
        border-bottom: solid 1px var(--color-light);
    }
    @media screen and (min-width: 1118px) and (max-width: 1400px) {
        width: 223px;
        min-height: 645px;
    }
    @media screen and (min-width: 1118px) {
        &::after,
        &::before {
            background-image: ${({ backgroundImage }) => backgroundImage ? 'url(' + backgroundImage + ')' : 'none'};
            background-position: bottom;
            background-repeat: no-repeat;
            background-size: cover;
            content: "";
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            opacity: 0;
            transition: opacity 500ms ease-in-out;
        }
        &:hover {
            width: 399px;
            @media screen and (max-width: 1400px) {
                width: 355px;
            }
            &::after,
            &::before {
                opacity: 1;
            }
            .brandLogo {
                opacity: 1;
            }
        }
    }
    
`;

export const BrandNumber = styled(Title)`
    z-index: 1;
    @media screen and (max-width: 1118px) {
        font-size: 2rem;
        line-height: 2.4rem;
        margin-bottom: 6.5px;
        margin-right: 10px;
        width: 54px;
        display: flex;
        align-items: center;
    }
    @media screen and (min-width: 1118px) and (max-width: 1400px) {
        font-size: 5.25rem;
        line-height: 6.5rem;
    }
`;

export const BrandFooter = styled.div`
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @media screen and (max-width: 1118px) {
        align-items: center;
    }
`;

export const BrandLogo = styled.div`
    @media screen and (max-width: 1118px) {
        position: relative;
        display: flex;
        align-items: center;
        flex: 1;
        padding: 0 25px;
        height: 100%;
        overflow: hidden;
        /* background-image: ${({ backgroundMobileImage }) => backgroundMobileImage ? 'url(' + backgroundMobileImage + ')' : 'none'};
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 40%; */
        svg {
            z-index: 1;
            width: auto;
            height: 20px;
        }
        
        &.brandLogo-natura {
            svg {
                height: 40px;
            }
        }
        &.brandLogo-ifood,
        &.brandLogo-magalu {
            svg {
                height: 30px;
            }
        }
        
    }
    @media screen and (min-width: 1118px) {
        opacity: 0;
        transition: opacity 500ms ease-in-out;
    }
`;

export const BrandArrow = styled.div`
    @media screen and (max-width: 1118px) {
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
`;