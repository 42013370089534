import styled from 'styled-components'

// COMPONENTS
import { Section } from '../../components/section/Section.styles'
import { Wrapper } from '../../components/wrapper/Wrapper.styles'
import { Text } from '../../components/text/Text.styles'
import { Button } from '../../components/button/Button.styles'

export const SectionContainer = styled(Section)`
    min-height: auto;
    background-color: var(--color-dark);
    color: var(--color-ligth);
`;

export const SectionWrapper = styled(Wrapper)`
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 6rem 3.75rem 3rem;
    @media screen and (max-width: 1118px) {
        padding: 6rem 1.875rem 1.875rem;
    }
`;

export const Content = styled.div`
    display: flex;
    align-items: flex-end;
    color: var(--color-warm);
    max-width: 1118px;
    width: 100%;
`;

export const ContentBody = styled.div`
    .logo-footer {
        img {
            max-width: 222px;
            width: auto;
            height: auto;
            @media screen and (max-width: 1118px) {
                width: 148.53px;
            }
        }
    }
`;

export const ContentText = styled(Text)`
    margin: 2rem 0;
    @media screen and (max-width: 1118px) {
        font-size: 1.063rem;
        line-height: 1.594rem;
        margin: 1.25rem 0 2.5rem;
    }
`;

export const ContentButton = styled(Button)`
    @media screen and (max-width: 1118px) {
        background-color: var(--color-1);
        font-size: 1rem;
        line-height: 1.5rem;
        padding: 9px 15px;
    }
`;

export const ContentFooter = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
    width: 100%;
`;

export const ContentFooterCopy = styled(Text)`
    br {
        display: none;
    }
    @media screen and (max-width: 1118px) {
        max-width: 140px;
        br {
            display: block;
        }
        span {
            display: none;
        }
    }
`;

export const ContentFooterIcons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 22px;
    @media screen and (max-width: 1118px) {
        gap: 17px;
    }
    svg {
        height: 32px;
        width: auto;
        @media screen and (max-width: 1118px) {
            height: 23px;
        }
    }
`;

export const ArrowRight = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin-right: 90px;
    @media screen and (max-width: 1118px) {
        margin-right: 53px;
    }
    svg {
        margin-bottom: 10.5px;
        @media screen and (max-width: 1118px) {
            width: 12.02px;
            height: 12.02px;
        }
    }
`;