import React from 'react'
import { Link } from 'react-router-dom'

// ASSETS
import logoWhite from '../../assets/images/logo-white.png'
import { ReactComponent as IconArrowRight } from '../../assets/images/icon-arrow-right.svg'
import { ReactComponent as IconInstagram } from '../../assets/images/icon-instagram.svg'
import { ReactComponent as IconLinkedin } from '../../assets/images/icon-linkedin.svg'

// STYLES
import * as Styled from './Footer.styles'

// DATA
import data from '../../data/footer'

const Footer = () => {
    return (
        <Styled.SectionContainer id="footer">
            <Styled.SectionWrapper>
                <Styled.Content>
                    <Styled.ArrowRight>
                        <IconArrowRight />
                    </Styled.ArrowRight>
                    <Styled.ContentBody>
                        <Link to="/" className="logo-footer">
                            <img src={logoWhite} className="logo-white" alt="Logo" />
                        </Link>
                        <Styled.ContentText
                            fontSize="1.875rem"
                            colorName="--color-light"
                            dangerouslySetInnerHTML={{ __html: data.text }}
                        />
                        <Styled.ContentButton
                            colorName="--color-dark"
                            backgroundColor="--color-4"
                            dangerouslySetInnerHTML={{ __html: data.button }}
                        />
                    </Styled.ContentBody>
                </Styled.Content>
                <Styled.ContentFooter>
                    <Styled.ContentFooterCopy
                        colorName="--color-light"
                        dangerouslySetInnerHTML={{ __html: data.copy }}
                    />
                    <Styled.ContentFooterIcons>
                        <Link to={{ pathname: "https://www.instagram.com/t1melens/" }} target="_blank" className="btn-social">
                            <IconInstagram />
                        </Link>
                        <Link to={{ pathname: "https://www.linkedin.com/company/t1melens/" }} target="_blank" className="btn-social">
                            <IconLinkedin />
                        </Link>
                    </Styled.ContentFooterIcons>
                </Styled.ContentFooter>
            </Styled.SectionWrapper>
        </Styled.SectionContainer>
    );
}

export default Footer
