import styled from 'styled-components'

export const Section = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: var(--color-light);
    color: var(--color-dark);
    overflow: hidden;
    /* Avoid Chrome to see Safari hack */
    @supports (-webkit-touch-callout: none) {
        /* The hack for Safari */
        min-height: -webkit-fill-available;
    }
`;