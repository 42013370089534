import styled from 'styled-components'

// COMPONENTS
import { Section } from '../../../components/section/Section.styles'
import { Wrapper } from '../../../components/wrapper/Wrapper.styles'
import { Title } from '../../../components/title/Title.styles'
import { Text } from '../../../components/text/Text.styles'

export const SectionContainer = styled(Section)``;

export const SectionContainerMask = styled.div`
    width: 10.5%;
    height: 100%;
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    @media screen and (max-width: 1118px) {
        width: 5%;
        justify-content: flex-start;
        top: 73px;
    }
    div {
        width: 100%;
        height: 2px;
        background-color: black;
        &:nth-of-type(1) {
            margin-bottom: 99px;
            @media screen and (max-width: 1118px) {
                margin-bottom: 37px;
            }
        }
        &:nth-of-type(2) {
            margin-bottom: 173px;
            @media screen and (max-width: 1118px) {
                margin-bottom: 37px;
            }
        }
        &:nth-of-type(3) {
            margin-bottom: 173px;
            @media screen and (max-width: 1118px) {
                margin-bottom: 21px;
            }
        }
        &:nth-of-type(4) {
            margin-bottom: 155px;
            @media screen and (max-width: 1118px) {
                margin-bottom: 0;
            }
        }
    }
`;

export const SectionWrapper = styled(Wrapper)`
    justify-content: center;
    align-items: center;
    padding: 11.25rem 3.75rem;
    @media screen and (max-width: 1118px) {
        padding: var(--padding);
        align-items: flex-start;
    }
`;

export const Content = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: var(--color-light);
    max-width: 1118px;
    width: 100%;
    height: 100%;
    &::before {
        display: block;
        position: absolute;
        left: 0;
        content: "";
        width: 426px;
        height: 555px;
        background-image: ${({ backgroundImage }) => backgroundImage ? 'url(' + backgroundImage + ')' : 'none'};
        background-repeat: no-repeat;
        background-size: cover;
        @media screen and (max-width: 1118px) {
            left: unset;
            right: 0;
            top: 0; 
            width: 209px;
            height: 295px;
        }
    }
    &::after {
        display: block;
        content: "10 00 11";
        font-family: PoppinsMedium;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        color: var(--color-dark);
        white-space: nowrap;
        transform: rotate(90deg) translate(15px, 15px);
        position: absolute;
        right: 0;
        top: 0;
        @media screen and (max-width: 1118px) {
            left: 0;
            right: unset;
        }
    }
`;

export const ContentBody = styled.div`
    width: 70%;
    z-index: 1;
    @media screen and (max-width: 1118px) {
        width: 100%;
        margin-top: 9.5em;
    }
`;

export const ContentTitle = styled(Title)`
    width: 100%;
    margin-bottom: 4.5rem;
    max-width: 530px;
    @media screen and (max-width: 1118px) {
        font-size: 2.125rem;
        line-height: 2.563rem;
        margin-bottom: 8.125rem;
    }
    br {
        display: none;
        @media screen and (max-width: 1118px) {
            display: block;
        }
    }
`;

export const ContentTextWrapper = styled.div`
    position: relative;
    max-width: 350px;
    float: right;
    @media screen and (max-width: 1118px) {
        max-width: 100%;
        float: unset;
        .btn-modal {
            margin-top: 40px;
        }
    }
    svg {
        position: absolute;
        bottom: 11.5px;
        left: -120px;
        @media screen and (max-width: 1118px) {
            position: relative;
            display: block;
            bottom: unset;
            left: unset;
            transform: rotate(90deg);
        }
    }
`;

export const ContentText = styled(Text)`
    margin-bottom: 4.5rem;

`;
export const ButtonDesc = styled(Text)`
    display: none;
    @media screen and (max-width: 1118px) {
        display: block;
        margin-top: 40px;
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.688rem;
    }
`;