import styled from 'styled-components'

// COMPONENTS
import { Section } from '../../../components/section/Section.styles'
import { Wrapper } from '../../../components/wrapper/Wrapper.styles'
import { Title } from '../../../components/title/Title.styles'
import { Text } from '../../../components/text/Text.styles'

export const SectionContainer = styled(Section)`
    background-color: var(--color-dark);
`;

export const SectionWrapper = styled(Wrapper)`
    flex: 0;
    justify-content: center;
    align-items: center;
    padding: var(--padding);
    z-index: 1;
    &:nth-of-type(1) {
        padding: 10.625rem 3.75rem 0;
        @media screen and (max-width: 1118px) {
            padding: var(--padding);
        }
        .textWrapper {
            position: relative;
            margin-top: 8.375rem;
            max-width: 539px;
            @media screen and (max-width: 1118px) {
                margin-top: 6rem;
                max-width: 100%;
            }
            @media screen and (min-width: 1118px) {
                &::after {
                    display: block;
                    content: "011011";
                    font-family: PoppinsMedium;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 2.375rem;
                    line-height: 3.563rem;
                    color: var(--color-warm);
                    white-space: nowrap;
                    transform: rotate(90deg) translateX(28px);
                    position: absolute;
                    bottom: 0;
                    right: -300px;
                    opacity: 0.3;
                }
            }
        }
    }
    &:nth-of-type(2) {
        min-height: 339px;
        margin-top: 6.125rem;
        @media screen and (max-width: 1118px) {
            flex-direction: column;
            margin-top: 0;
        }
        &::before {
            display: block;
            content: "";
            background-image: ${({ backgroundImage }) => backgroundImage ? 'url(' + backgroundImage + ')' : 'none'};
            background-repeat: no-repeat;
            background-size: cover;
            height: 339px;
            width: 709px;
            left: 0;
            position: absolute;
            @media screen and (max-width: 1118px) {
                position: relative;
                height: 208px;
                width: calc(100% + 1.875rem);
                margin-bottom: 2.5rem;
                margin-left: -1.875rem;
            }
        }
        .textWrapper {
            max-width: 432px;
            align-self: flex-end;
            @media screen and (max-width: 1118px) {
                align-self: center;
                max-width: 100%;
            }
        }
    }
    &:nth-of-type(3) {
        padding: 0 3.75rem 10.625rem;
        margin-top: 6.125rem;
        @media screen and (max-width: 1118px) {
            margin-top: 0;
            padding: var(--padding);
        }
        .textWrapper {
            max-width: 526px;
            margin-left: 7rem;
            @media screen and (max-width: 1118px) {
                max-width: 100%;
                margin-left: 0;
            }
        }
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: var(--color-light);
    max-width: 1118px;
    width: 100%;
    height: 100%;
`;

export const ContentTitle = styled(Title)`
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    @media screen and (max-width: 1118px) {
        font-size: 2.5rem;
        line-height: 3rem;
    }
    span {
        display: inline-block;
        color: var(--color-5);
        font-size: 2.5rem;
        line-height: 120%;
        transform: rotate(45deg);
        padding: 30px;
        @media screen and (max-width: 1118px) {
            font-size: 0.75rem;
            line-height: 2rem;
            padding: 0 16px;
        }
    }
`;

export const ContentTextWrapper = styled.div`
    
`;

export const ContentTextOne = styled(Text)`
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: var(--color-light);
`;

export const ContentTextTwo = styled(Text)`
    font-size: 1.75rem;
    line-height: 2.125rem;
    color: var(--color-light);
    @media screen and (max-width: 1118px) {
        font-size: 1.125rem;
        line-height: 1.35rem;
    }
`;

export const ContentTextThree = styled(Text)`
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: var(--color-light);
`;