const modal = {
    title: "Veja também",
    textOne: "top cinco",
    linkOne: "/#topFive",
    textTwo: "destaques",
    linkTwo: "/#highlights",
    textThree: "metodologia",
    linkThree: "/#methodology"
};

export default modal