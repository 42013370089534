import React from 'react'
import { Helmet } from 'react-helmet-async'

// MODULES
import ComparisonContent from '../modules/comparison/comparisonContent/ComparisonContent'
import Footer from '../modules/footer/Footer'
import Header from '../modules/header/Header'
import KnowMore from '../modules/knowMore/KnowMore'
import ViewMore from '../modules/viewMore/ViewMore'
import Modal from '../modules/modal/Modal'

const Comparison = () => {
    return (
        <>
            <Helmet>
                <title>Future‑proof Score - Comparison</title>
                <meta name="description" content="Future‑proof Score - Estudo das marcas mais preparadas para o futuro no cenário brasileiro." />
            </Helmet>
            <div id="comparison">
                <Header className="dark-header" />
                <ComparisonContent />
                <KnowMore />
                <ViewMore />
                <Footer />
                <Modal />
            </div>
        </>
    );
}

export default Comparison