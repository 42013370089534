import styled from 'styled-components'

// COMPONENTS
import { Section } from '../../components/section/Section.styles'
import { Wrapper } from '../../components/wrapper/Wrapper.styles'
import { Text } from '../../components/text/Text.styles'
import { Button } from '../../components/button/Button.styles'

export const SectionContainer = styled(Section)`
    min-height: auto;
`;

export const SectionWrapper = styled(Wrapper)`
    padding: 7.5rem 3.75rem 8.625rem;
    @media screen and (max-width: 1118px) {
        padding: var(--padding);
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 7rem;
    @media screen and (max-width: 1118px) {
        padding: 0;
    }
`;

export const ContentTitle = styled(Text)`
    width: 100%;
    font-size: 1.125rem;
    margin-bottom: 3.5rem;
    color: var(--color-6);
    @media screen and (max-width: 1118px) {
        font-size: 0.938rem;
        line-height: 1.375rem;
        margin-bottom: 2.5rem;
    }
`;

export const ContentBody = styled.div`
    display: flex;
    gap: 90px;
    @media screen and (max-width: 1118px) {
        flex-direction: column;
        gap: 0;
        margin-left: 60px;
    }
    a {
        border-top: 2px solid var(--color-dark);
        text-decoration: none;
        color: var(--color-dark);
        font-family: Dystopian;
        font-style: normal;
        font-weight: bold;
        font-size: 2.25rem;
        line-height: 2.475rem;
        padding: 20px 0;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-basis: 100%;
        @media screen and (max-width: 1118px) {
            font-size: 1.319rem;
            line-height: 1.438rem;
            padding: 12px 0 24px;
        }
        svg {
            margin-top: 8px;
            @media screen and (max-width: 1118px) {
                width: 12.44px;
                height: 12.44px;
            }
            path {
                stroke: var(--color-dark);
            }
        }
    }
`;

export const ContentText = styled(Text)`
    margin: 0 0 2rem;
`;

export const ContentButton = styled(Button)``;

export const ArrowRight = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin-right: 90px;
    svg {
        margin-bottom: 10.5px;
    }
`;