import React from "react"
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import Text from "../text/Text"

// Import Swiper styles
import "swiper/swiper-bundle.min.css"
import "swiper/modules/pagination/pagination.min.css"

// STYLES
import * as Styled from './Carousel.styles'


const Carousel = (props) => {
    const { slides } = props
    return (
        <Styled.Carousel>
            <Swiper
                modules={[Autoplay, Pagination]}
                spaceBetween={0}
                slidesPerView={1}
                pagination={{
                    el: '.swiper-pagination',
                    clickable: true,
                }}
                autoplay
            >
                {slides && slides.map((slide, index) => {
                    return (
                        <SwiperSlide key={index}>
                            <Text>{slide}</Text>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
            <Styled.Pagination className="swiper-pagination" />
        </Styled.Carousel>
    )
}

export default Carousel
