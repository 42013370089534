import styled from 'styled-components'

export const Menu = styled.div`
    position: relative;
    width: 35px;
    height: 35px;
`
export const Nav = styled.nav`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    background: var(--color-dark);
    transform: ${({ open }) => open ? 'translateY(0)' : 'translateY(-150%)'};
    width: 100%;
    transition: transform 500ms ease-in-out;
    display: flex;
    justify-content: center;
    text-align: left;
    a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: var(--color-warm);
        text-decoration: none;
        transition: color 0.3s linear;
        font-family: Dystopian;
        font-style: normal;
        font-size: 3.75rem;
        font-weight: 700;
        line-height: 4.5rem;
        border-bottom: var(--border-menu);
        padding: 0.625rem 0 1.25rem;
        @media screen and (max-width: 1118px) {
            font-size: 1.75rem;
            line-height: 2.5rem;
        }
        &:hover {
            color: var(--color-light);
        }
        span {
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 1.125rem;
            line-height: 1.688rem;
            color: var(--color-warm);
            opacity: 0.4;
            margin-left: 40px;
            margin-top: 20px;
            @media screen and (max-width: 1118px) {
                font-size: 0.75rem;
                line-height: 1rem;
                margin-left: 20px;
                margin-top: 10px;
            }
        }
    }
    
`
export const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1440px;
    width: 100%;
    height: 100%;
    flex: 1;
    padding: var(--padding);
`;