import styled from 'styled-components'

export const Button = styled.button`
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 35px;
    height: 35px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 9999;
    outline: none;
    &:focus {
        outline: none;
    }
    div {
        width: 35px;
        height: 2px;
        background-color: var(--color-dark);
        position: relative;
        transition: all 250ms ease-out;
        will-change: transform;
        :first-child {
            transform: translateY(18px) rotate(45deg);
        }
        :nth-child(2) {
            transform: translateY(16px) rotate(-45deg);
        }
    }
`;