import React from 'react';
import ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter as Router } from "react-router-dom"
import './index.css';
import App from './App';
import ScrollToTop from './components/scrollToTop/ScrollToTop';

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<ScrollToTop>
				<HelmetProvider>
					<App />
				</HelmetProvider>
			</ScrollToTop>
		</Router>
	</React.StrictMode>,
	document.getElementById('root')
);