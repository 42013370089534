import React from 'react'
import { Helmet } from 'react-helmet-async'
import {
    Switch,
    Route,
    useRouteMatch
} from 'react-router-dom'

// COMPONENTS
import RedirectToHome from '../components/redirectToHome/RedirectToHome'

// MODULES
import BrandContent from '../modules/brand/brandContent/BrandContent'
import Footer from '../modules/footer/Footer'
import Header from '../modules/header/Header'
import KnowMore from '../modules/knowMore/KnowMore'
import ViewMore from '../modules/viewMore/ViewMore'
import Modal from '../modules/modal/Modal'

const Brand = () => {
    const match = useRouteMatch()
    return (
        <Switch>
            <Route path={`${match.path}/:brandSlug`}>
                <Helmet>
                    <title>Future‑proof Score - Top Cinco</title>
                    <meta name="description" content="Future‑proof Score - Estudo das marcas mais preparadas para o futuro no cenário brasileiro." />
                </Helmet>
                <div id="brand">
                    <Header className="dark-header" />
                    <BrandContent />
                    <KnowMore />
                    <ViewMore />
                    <Footer />
                    <Modal />
                </div>
            </Route>
            <RedirectToHome />
        </Switch>
    )
}

export default Brand