import React from "react"

// STYLES
import * as Styled from './Checkbox.styles'

const Checkbox = (props) => {
    const { label, name, value, handleCheckboxClick } = props
    return (
        <Styled.Label>
            <Styled.Checkbox name={name} value={value} onClick={handleCheckboxClick} />
            <span className="checkmark"></span>
            {label}
        </Styled.Label>
    )
}

export default Checkbox
