import React, { useEffect } from "react"
import {
	Switch,
	Route
} from "react-router-dom"
import TagManager from 'react-gtm-module'

// PAGES
import Brand from "./pages/Brand"
import Comparison from "./pages/Comparison"
import Home from "./pages/Home"

const tagManagerArgs = {
    gtmId: 'GTM-MQRG6WV'
}

TagManager.initialize(tagManagerArgs)

const App = () => {

	const handleHeaderAndFooter = () => {
		let headerContainer = document.getElementById('header')
		let footerContainer = document.getElementById('footer')
		let iconDownload = document.getElementById('icon-download')
		let maxHeight = 50
		if (window.pageYOffset > maxHeight) {
			headerContainer.classList.add('active')
		} else {
			headerContainer.classList.remove('active')
		}
		if (iconDownload) {
			let footerMaxOffset = (footerContainer.offsetTop - (footerContainer.offsetHeight * 2))
			if (window.pageYOffset > footerMaxOffset) {
				iconDownload.classList.add('icon-download-footer')
			} else {
				iconDownload.classList.remove('icon-download-footer')
			}
		}
	}

	useEffect(() => {
		window.addEventListener('scroll', handleHeaderAndFooter, false)
		return () => {
			window.removeEventListener('scroll', handleHeaderAndFooter, false)
		};
	})

	// const handleModal = useCallback((e) => {
	// 	const modalDownload = document.getElementById('modal-download')
	// 	modalDownload.classList.toggle('modal-active')
	// 	console.log('oioio')
	// }, [])

	// useEffect(() => {
	// 	document.querySelectorAll(".btn-modal-open, .btn-modal-close").forEach(btn =>
	// 		btn.addEventListener("click", handleModal, false)
	// 	)

	// 	return () => {
	// 		document.querySelectorAll(".btn-modal-open, .btn-modal-close").forEach(btn =>
	// 			btn.removeEventListener("click", handleModal, false)
	// 		)
	// 	}
	// }, [handleModal])

	return (
		<Switch>
			<Route path="/comparison">
				<Comparison />
			</Route>
			<Route path="/brand">
				<Brand />
			</Route>
			<Route exact path="/">
				<Home />
			</Route>
		</Switch>
	)
}

export default App
