import React from 'react'

// ASSETS
import backgroundContent from '../../../assets/images/background-comparison.jpg'

// STYLES
import * as Styled from './Comparison.styles'

// DATA
import data from '../../../data/home/comparison'

const Comparison = () => {

    const onClickButton = (path) => {
        setTimeout(function(){ window.location.assign(path) }, 200);
    }

    return (
        <Styled.SectionContainer id="comparison">
            <Styled.SectionWrapper>
                <Styled.Content backgroundImage={backgroundContent}>
                    <Styled.ContentMask>
                        <Styled.Date>10 00 11</Styled.Date>
                        <Styled.Date>10 00 11</Styled.Date>
                        <Styled.Date>10 00 11</Styled.Date>
                        <Styled.Date>10 00 11</Styled.Date>
                    </Styled.ContentMask>
                    <Styled.ContentBody>
                        <Styled.ContentTitle
                            colorName="--color-light"
                            lineHeight="4.5rem"
                            fontSize="3.75rem"
                            dangerouslySetInnerHTML={{ __html: data.title }}
                        />
                        <Styled.ContentTextWrapper>
                            <Styled.ContentText
                                colorName="--color-light"
                                dangerouslySetInnerHTML={{ __html: data.text }}
                            />
                            <Styled.ContentButton
                                backgroundColor="--color-1"
                                dangerouslySetInnerHTML={{ __html: data.button }}
                                onClick={() => onClickButton(data.link)}
                                className="btn-compare-green"
                            />
                        </Styled.ContentTextWrapper>
                    </Styled.ContentBody>
                </Styled.Content>
            </Styled.SectionWrapper>
        </Styled.SectionContainer>
    );
}

export default Comparison
