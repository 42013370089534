import React from 'react'

// MODULES
import BrandChart from '../../../components/brandChart/BrandChart'

// STYLES
import * as Styled from './BrandAbout.styles'

// DATA
import data from '../../../data/brand/about'

const BrandAbout = (props) => {
    const { brand } = props
    return (
        <Styled.SectionContainer id="brandAbout">
            <Styled.SectionWrapper>
                <Styled.Content>
                    <Styled.ContentTitle
                        dangerouslySetInnerHTML={{ __html: data.title }}
                    />
                    <Styled.ContentBody>
                        <Styled.ContentText
                            dangerouslySetInnerHTML={{ __html: brand.about }}
                        />
                        <Styled.ContentChart>
                            <BrandChart
                                colors={[brand.color]}
                                series={[
                                    {
                                        name: brand.name,
                                        data: brand.data
                                    }
                                ]}
                            />
                        </Styled.ContentChart>
                    </Styled.ContentBody>
                </Styled.Content>
                <Styled.ContentRank />
            </Styled.SectionWrapper>
        </Styled.SectionContainer>
    );
}

export default BrandAbout