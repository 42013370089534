import styled from 'styled-components'

export const Label = styled.label`
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 150%;
    /* or 15px */
    display: flex;
    align-items: center;
    justify-content: space-between;

    color: var(--color-dark);
    
    /* Create a custom checkbox */
    .checkmark {
        position: relative;
        display: inline-block;
        height: 16px;
        min-height: 16px;
        width: 16px;
        min-width: 16px;
        background-color: var(--color-light);
        border: 1px solid var(--color-dark);
        margin-right: 12px;
    }

    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
        background-color: var(--color-warm);
    }

    /* When the checkbox is checked, add a blue background */
    input:checked ~ .checkmark {
        background-color: var(--color-dark);
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .checkmark:after {
        left: 5px;
        top: 1px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
`