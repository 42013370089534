import styled from 'styled-components'

export const Button = styled.button`
    padding: 9px 41px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    background-color: ${({ backgroundColor }) => backgroundColor ? 'var(' + backgroundColor + ')' : 'var(--color-warm)'};
    color: ${({ colorName }) => colorName ? 'var(' + colorName + ')' : 'var(--color-dark)'};
    box-shadow: none;
    outline: none;
    border: none;
    cursor: pointer;
`;