import styled from 'styled-components'

export const Text = styled.p`
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: ${({ fontSize }) => fontSize ? fontSize : '0.938rem'};
    line-height: ${({ lineHeight }) => lineHeight ? lineHeight : '1.688rem'};
    color: ${({ colorName }) => colorName ? 'var(' + colorName + ')' : 'var(--color-dark)'};
    margin: 0;
    padding: 0;
`;