import React from 'react'

// STYLES
import * as Styled from './ButtonClose.styles'

const ButtonClose = (props) => {
    const { className, handleOnClick } = props
    return (
        <Styled.Button className={className} onClick={handleOnClick}>
            <div />
            <div />
        </Styled.Button>
    )
}

export default ButtonClose
