const modal = {
    title: "Quais são as marcas melhor preparadas para o futuro?",
    text: "Para receber, em primeira mão, o Future-proof Score e conhecer esse novo olhar sobre a potência das marcas, preencha o formulário abaixo:",
    name: "Nome*",
    lastName: "Sobrenome*",
    email: "E-mail*",
    cpf: "CPF*",
    company: "Empresa",
    role: "Cargo",
    checkbox: "Ao fornecer meus dados e confirmar minha inscrição, autorizo a Timelens a coletar e armazenar minhas informações de contato para envio do estudo Future-proof Score e futuras comunicações.",
    button: "Baixe o estudo"
};

export default modal