import React from 'react'

import ButtonModal from '../../../components/buttonModal/ButtonModal'

// ASSETS
import backgroundHeroSVG from '../../../assets/images/background-hero.jpg'
import backgroundHeroVideo from '../../../assets/videos/hero.mp4'
import { ReactComponent as IconDownload } from '../../../assets/images/icon-download.svg'
import { ReactComponent as LogoFutureBrand } from '../../../assets/images/logo-futureBrand.svg'

// STYLES
import * as Styled from './Hero.styles'

// DATA
import data from '../../../data/home/hero'

const Hero = () => {
    return (
        <Styled.SectionContainer id="hero" backgroundImage={backgroundHeroSVG}>
            <Styled.SectionBackground id="heroVideo" autoPlay loop muted>
                <source src={backgroundHeroVideo} type="video/mp4" />
            </Styled.SectionBackground>
            <Styled.SectionWrapper>
                <Styled.Content>
                    <Styled.ContentTitle
                        colorName="--color-warm"
                        lineHeight="100%"
                        fontSize="7.5rem"
                        dangerouslySetInnerHTML={{ __html: data.title }}
                    />
                    <Styled.ContentText
                        colorName="--color-warm"
                        fontSize="1.125rem"
                        dangerouslySetInnerHTML={{ __html: data.text }}
                    />
                </Styled.Content>
                <Styled.ContentFooter>
                    <Styled.LogosWrapper>
                        <LogoFutureBrand />
                    </Styled.LogosWrapper>
                    <Styled.IconDownloadWrapper>
                        <ButtonModal id="icon-download">
                            <IconDownload />
                        </ButtonModal>
                    </Styled.IconDownloadWrapper>
                </Styled.ContentFooter>
            </Styled.SectionWrapper>
        </Styled.SectionContainer>
    );
}

export default Hero
