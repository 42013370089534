import React from 'react'

import ButtonModal from '../../components/buttonModal/ButtonModal'

// ASSETS
import { ReactComponent as IconArrowRight } from '../../assets/images/icon-arrow-right.svg'

// STYLES
import * as Styled from './KnowMore.styles'

// DATA
import data from '../../data/knowMore'

const KnowMore = () => {
    return (
        <Styled.SectionContainer id="knowMore">
            <Styled.SectionWrapper>
                <Styled.Content>
                    <Styled.ArrowRight>
                        <IconArrowRight />
                    </Styled.ArrowRight>
                    <Styled.ContentBody>
                        <Styled.ContentText
                            fontSize="1.875rem"
                            colorName="--color-light"
                            dangerouslySetInnerHTML={{ __html: data.text }}
                        />
                        <ButtonModal
                            colorName="--color-dark"
                            backgroundColor="--color-light"
                            className="btn-modal"
                        >
                            {data.button}
                        </ButtonModal>
                    </Styled.ContentBody>
                </Styled.Content>
            </Styled.SectionWrapper>
        </Styled.SectionContainer>
    );
}

export default KnowMore
