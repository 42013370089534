import React from 'react'

// STYLES
import * as Styled from './Select.styles'

const Select = (props) => {
    const { className, label, handleChange, options, selected } = props
    return (
        <Styled.Wrapper className={className}>
            <Styled.Label>{label}</Styled.Label>
            <Styled.Select value={selected} onChange={handleChange}>
                {options && options.map((option, index) => <option key={index} value={option}>{option}</option>)}
            </Styled.Select>
        </Styled.Wrapper>
    );
}

export default Select
