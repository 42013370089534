import React from 'react'

// ASSETS
import { ReactComponent as IconArrowDown } from '../../../assets/images/icon-arrow-down.svg'
import backgroundWrapper from '../../../assets/images/background-methodology.jpg'

// STYLES
import * as Styled from './Methodology.styles'

// DATA
import data from '../../../data/home/methodology'


import Carousel from '../../../components/carousel/Carousel'

const Methodology = () => {
    return (
        <Styled.SectionContainer id="methodology">
            <Styled.SectionWrapper backgroundImage={backgroundWrapper}>
                <Styled.Content>
                    <Styled.ContentHeader>
                        <Styled.ContentTitle
                            colorName="--color-dark"
                            lineHeight="9rem"
                            fontSize="7.5rem"
                            dangerouslySetInnerHTML={{ __html: data.title }}
                        />
                        <Styled.Arrows>
                            <IconArrowDown />
                            <IconArrowDown />
                            <IconArrowDown />
                        </Styled.Arrows>
                    </Styled.ContentHeader>
                    <Styled.ContentBody>
                        <Carousel slides={data.slides} />
                    </Styled.ContentBody>
                </Styled.Content>
            </Styled.SectionWrapper>
        </Styled.SectionContainer>
    );
}

export default Methodology
