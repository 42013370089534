import React from 'react'

// STYLES
import * as Styled from './ButtonBurger.styles'

const ButtonBurger = (props) => {
    const { className, onClickButton, open } = props
    return (
        <Styled.ButtonBurger open={open} className={className} onClick={onClickButton}>
            <div />
            <div />
        </Styled.ButtonBurger>
    )
}

export default ButtonBurger
