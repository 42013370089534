import React from 'react'
import { HashLink as Link } from 'react-router-hash-link';

// ASSETS
import { ReactComponent as IconArrowRight } from '../../assets/images/icon-arrow-right.svg'

// STYLES
import * as Styled from './ViewMore.styles'

// DATA
import data from '../../data/viewMore'

const ViewMore = () => {
    return (
        <Styled.SectionContainer id="knowMore">
            <Styled.SectionWrapper>
                <Styled.Content>
                    <Styled.ContentTitle
                        dangerouslySetInnerHTML={{ __html: data.title }}
                    />
                    <Styled.ContentBody>
                        <Link to={data.linkOne} className="view-more">
                            {data.textOne}
                            <IconArrowRight />
                        </Link>
                        <Link to={data.linkTwo} className="view-more">
                            {data.textTwo}
                            <IconArrowRight />
                        </Link>
                        <Link to={data.linkThree} className="view-more">
                            {data.textThree}
                            <IconArrowRight />
                        </Link>
                    </Styled.ContentBody>
                </Styled.Content>
            </Styled.SectionWrapper>
        </Styled.SectionContainer>
    );
}

export default ViewMore
