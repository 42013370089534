import React from 'react'

// ASSETS
import { ReactComponent as IconArrowRight } from '../../../assets/images/icon-arrow-right-black.svg'

// STYLES
import * as Styled from './BrandInfo.styles'

// DATA
import data from '../../../data/brand/info'

const BrandInfo = (props) => {
    const { brand, positions } = props

    const onClickPosition = (slug) => {
        window.location.assign(`/brand/${slug}`)
    }

    return (
        <Styled.SectionContainer id="brandInfo">
            <Styled.SectionWrapper>
                <Styled.Content backgroundImage={brand.background}>
                    <Styled.ContentTitle
                        dangerouslySetInnerHTML={{ __html: data.title }}
                    />
                    <Styled.ContentBody>
                        <Styled.BrandLogo>
                            {brand.logo}
                        </Styled.BrandLogo>
                        <Styled.BrandInfo>
                            <Styled.BrandInfoBox>
                                <Styled.BrandNumber
                                    colorValue={brand.color}
                                    dangerouslySetInnerHTML={{ __html: `#${brand.position}` }}
                                />
                            </Styled.BrandInfoBox>
                            <Styled.BrandInfoBox>
                                <Styled.BrandInfoLabel>
                                    {data.score}
                                </Styled.BrandInfoLabel>
                                <Styled.BrandInfoValue
                                    dangerouslySetInnerHTML={{ __html: brand.score }}
                                />
                            </Styled.BrandInfoBox>
                        </Styled.BrandInfo>
                    </Styled.ContentBody>
                </Styled.Content>
                <Styled.ContentRank>
                    {positions.map((item, index) => {
                        let actual = item.position === brand.position
                        return (
                            <Styled.RankPosition key={index}>
                                {actual && <IconArrowRight />}
                                <Styled.RankPositionNumber className={actual ? 'actual' : ''}
                                    dangerouslySetInnerHTML={{ __html: item.position }}
                                    onClick={() => onClickPosition(item.slug)}
                                />
                            </Styled.RankPosition>
                        )
                    })}
                </Styled.ContentRank>
            </Styled.SectionWrapper>
        </Styled.SectionContainer>
    );
}

export default BrandInfo