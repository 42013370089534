const menu = [
    {
        title: "home",
        link: "/"
    },
    {
        title: "destaques",
        link: "/#highlights"
    },
    {
        title: "compare as marcas",
        link: "/#comparison"
    },
    {
        title: "avalie sua marca <span>EM BREVE</span>",
        link: "/#score"
    },
    {
        title: "metodologia",
        link: "/#methodology"
    }
];

export default menu