import React, { useState } from 'react'
import { HashLink as Link } from 'react-router-hash-link'

// ASSETS
import logoWhite from '../../assets/images/logo-white.png'
import logoBlack from '../../assets/images/logo-black.png'

// STYLES
import * as Styled from './Header.styles'

// COMPONENTS
import Menu from '../../components/menu/Menu';

const Header = (props) => {
    const { className } = props
    const [openMenu, setOpenMenu] = useState(false)

    const onToogleMenu = () => {
        let headerContainer = document.getElementById('header')
        if (!openMenu) {
            headerContainer.classList.add('open')
        } else {
            headerContainer.classList.remove('open')
        }
        setOpenMenu(!openMenu)
    }

    return (
        <Styled.SectionContainer id="header" className={className}>
            <Styled.SectionWrapper className="wrapper">
                <Link to="/" className="logo">
                    <img src={logoWhite} className="logo-white" alt="Logo" />
                    <img src={logoBlack} className="logo-black" alt="Logo" />
                </Link>
                <Menu
                    onToogleMenu={onToogleMenu}
                    open={openMenu}
                />
            </Styled.SectionWrapper>
        </Styled.SectionContainer>
    );
}

export default Header
