import styled from 'styled-components'

// COMPONENTS
import { Section } from '../../../components/section/Section.styles'
import { Wrapper } from '../../../components/wrapper/Wrapper.styles'
import { Title } from '../../../components/title/Title.styles'
import { Text } from '../../../components/text/Text.styles'

export const SectionContainer = styled(Section)`
    padding-top: 130px;
    @media screen and (max-width: 1118px) {
        min-height: auto;
    }
`;

export const SectionWrapper = styled(Wrapper)`
    justify-content: center;
    align-items: flex-start;
`;

export const Content = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 600px;
    width: 100%;
    height: 100%;
    margin: 0 0 0 7rem;
    @media screen and (max-width: 1118px) {
        margin: 0;
    }
`;

export const ContentTitle = styled(Text)`
    display: flex;
    align-items: center;
    font-family: PoppinsMedium;
    font-size: 1.5rem;
    width: 100%;
    margin-bottom: 6.25rem;
    @media screen and (max-width: 1118px) {
        font-size: 0.938rem;
        line-height: 1.063rem;
        margin-bottom: 2.5rem;
    }
    span {
        font-family: DystopianBold;
        font-size: 2.5rem;
        line-height: 120%;
        margin-right: 28px;
        @media screen and (max-width: 1118px) {
            font-size: 1.375rem;
            line-height: 1.7rem;
        }
    }
`;

export const ContentBody = styled.div`
    width: 100%;
    display: flex;
    gap: 8.25rem;
    
    flex-grow: 1;
    @media screen and (max-width: 1118px) {
        flex-direction: column;
        padding: 0;
    }
`;

export const ContentTextWrapper = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1118px) {
        width: 100%;
    }
`;

export const ContentText = styled(Text)`
    font-size: 0.938rem;
    line-height: 1.406rem;
`;

export const ContentChartWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const ContentChart = styled.div`
    flex: 1;
    height: 100%;
    min-height: 593px;
    min-width: 637px;
    width: 100%;
    @media screen and (max-width: 1118px) {
        width: 100vw;
        position: relative;
        margin-left: -50vw;
        left: 50%;
        min-height: 460px;
        min-width: 100%;
        margin-bottom: 3.5rem;
        .apexcharts-xaxis {
            text {
                font-size: 9px;
            }
        }
    }
    .apexcharts-xaxis {
        .apexcharts-datalabel {
            &:first-of-type {
                transform: translateY(-10px);
            }
        }
    }
`;

export const ContentChartTitle = styled(Title)`
    font-size: 2.25rem;
    line-height: 2.5rem;
    span {
        display: inline-block;
        text-transform: lowercase;
        &:first-letter {
            text-transform: capitalize;
        }
    }
`;