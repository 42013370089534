import styled from 'styled-components'

// COMPONENTS
import { Section } from '../../../components/section/Section.styles'
import { Wrapper } from '../../../components/wrapper/Wrapper.styles'
import { Title } from '../../../components/title/Title.styles'
import { Text } from '../../../components/text/Text.styles'
import { Button } from '../../../components/button/Button.styles'

export const SectionContainer = styled(Section)`
    background-color: var(--color-dark);
`;

export const SectionWrapper = styled(Wrapper)`
    justify-content: center;
    align-items: center;
    padding: 8.125rem 3.75rem;
    @media screen and (max-width: 1118px) {
        align-items: flex-start;
        padding: var(--padding);
    }
`;

export const Content = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    color: var(--color-light);
    max-width: 1118px;
    width: 100%;
    height: 100%;
    @media screen and (max-width: 1118px) {
        align-items: flex-start;
    }
    &::before {
        display: block;
        position: absolute;
        left: 0;
        content: "";
        width: 650px;
        height: 659px;
        background-image: ${({ backgroundImage }) => backgroundImage ? 'url(' + backgroundImage + ')' : 'none'};
        background-repeat: no-repeat;
        background-size: cover;
        @media screen and (max-width: 1118px) {
            left: unset;
            right: 0;
            top: 0; 
            width: 182px;
            height: 295px;
        }
    }
`;

export const ContentMask = styled.div`
    width: 50vw;
    height: 100%;
    position: absolute;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-left: -28vw;
    z-index: 1;
    @media screen and (max-width: 1118px) {
        left: unset;
        margin-left: unset;
        top: 20px;
        right: 0;
        margin-right: -28vw;
        align-items: flex-start;
        justify-content: flex-start;
    }
`;

export const Date = styled.div`
    font-family: PoppinsMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: var(--color-light);
    white-space: nowrap;
    border-bottom: 2px solid var(--color-light);
    width: 100%;
    text-align: right;
    padding-bottom: 16px;
    @media screen and (max-width: 1118px) {
        text-align: left;
        font-size: 0.413rem;
        line-height: 0.619rem;
        padding-left: 10px;
    }
    &:nth-of-type(1) {
        margin-bottom: 88px;
        @media screen and (max-width: 1118px) {
            margin-bottom: 8px;
        }
    }
    &:nth-of-type(2),
    &:nth-of-type(3) {
        margin-bottom: 154px;
        @media screen and (max-width: 1118px) {
            margin-bottom: 52px;
        }
    }
`;

export const ContentBody = styled.div`
    width: 50%;
    z-index: 1;
    @media screen and (max-width: 1118px) {
        width: 80%;
        margin-top: 9.375rem;
    }
`;

export const ContentTitle = styled(Title)`
    max-width: 420px;
    width: 100%;
    margin-bottom: 4.5rem;
    @media screen and (max-width: 1118px) {
        font-size: 2.125rem;
        line-height: 2.563rem;
        margin-bottom: 11.25rem;
    }
`;

export const ContentTextWrapper = styled.div`
    max-width: 300px;
    float: right;
    @media screen and (max-width: 1118px) {
        max-width: 100%;
        float: unset;
    }
`;

export const ContentText = styled(Text)`
    margin-bottom: 4.5rem;
`;

export const ContentButton = styled(Button)`
`;