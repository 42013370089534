import { useEffect } from "react";
import { useLocation, withRouter } from "react-router-dom";

const ScrollToTop = withRouter((props) => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return props.children
})

export default ScrollToTop