import React from 'react'

// ASSETS
import { ReactComponent as IconArrowRight } from '../../../assets/images/icon-arrow-right.svg'

// STYLES
import * as Styled from './TopFive.styles'

// DATA
import data from '../../../data/home/topFive'
import brands from '../../../data/brands'

const TopFive = () => {

    const onClickBrand = (slug) => {

        setTimeout(function(){ window.location.assign(`/brand/${slug}`) }, 200);
        
    }

    return (
        <Styled.SectionContainer id="brands">
            <Styled.SectionWrapper>
                <Styled.Content>
                    <Styled.ContentTitle
                        colorName="--color-4"
                        lineHeight="6rem"
                        fontSize="5rem"
                        dangerouslySetInnerHTML={{ __html: data.title }}
                    />
                    <Styled.ContentText
                        colorName="--color-light"
                        dangerouslySetInnerHTML={{ __html: data.text }}
                    />
                </Styled.Content>
            </Styled.SectionWrapper>
            <Styled.Brands id="topFive">
                {brands.map((brand, index) => {
                    return (
                        <Styled.Brand key={index} >
                            {index !== brands.length - 1 &&
                                <Styled.Date className="dateArea">
                                    10 00 11
                                </Styled.Date>
                            }
                            <Styled.BrandContent
                                backgroundImage={brand.background}
                                onClick={() => onClickBrand(brand.slug)}
                                className="brand-item"
                            >
                                <Styled.BrandNumber
                                    colorName="--color-light"
                                    lineHeight="7.5rem"
                                    fontSize="6.25rem"
                                >
                                    {`#${brand.position}`}
                                </Styled.BrandNumber>
                                <Styled.BrandFooter>
                                    <Styled.BrandLogo
                                        className={`brandLogo brandLogo-${brand.slug}`}
                                    // backgroundImage={brand.background}
                                    // backgroundMobileImage={brand.backgroundMobile}
                                    >
                                        {brand.logoWhite}
                                    </Styled.BrandLogo>
                                    <Styled.BrandArrow>
                                        <IconArrowRight />
                                    </Styled.BrandArrow>
                                </Styled.BrandFooter>
                            </Styled.BrandContent>
                        </Styled.Brand>
                    )
                })}
            </Styled.Brands>
        </Styled.SectionContainer>
    );
}

export default TopFive
