import React from 'react'

// STYLES
import * as Styled from './Text.styles'

const Text = (props) => {
    const { className, colorName, children, lineHeight, fontSize } = props;
    return (
        <Styled.Text className={className} colorName={colorName} lineHeigh={lineHeight} fontSize={fontSize}>
            {children}
        </Styled.Text>
    );
}

export default Text
