import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 2.188rem
`

export const Label = styled.label`
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 0.938rem;
    line-height: 1.375rem;
    color: var(--color-dark);
    margin: 0 0 0.5rem;
    padding: 0;
`;

export const Select = styled.select`
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: #979797;
    margin: 0;
    padding: 10px;
    border: 1px solid var(--color-dark);
    border-radius: 0;
    outline: none;

`;