import React, { useEffect, useState, useRef, useCallback } from "react"

// HELPERS
import { cpfCheck, cpfMask } from '../../helpers/index'
import { PDF_FILE_URL } from '../../helpers/config'

// COMPONENTS
import ButtonClose from "../../components/buttonClose/ButtonClose"
import Checkbox from "../../components/checkbox/Checkbox"

// STYLES
import * as Styled from './Modal.styles'

// DATA
import data from '../../data/modal'

const Modal = (props) => {
    const modal = useRef(null)
    const form = useRef(null)
    const [cpf, setCpf] = useState('')

    const handleCloseModal = () => {
        modal.current.classList.toggle('modal-active')
    }

    const handleCpfInvalid = useCallback(() => {
        let cpfMsg = document.querySelector('.cpf-validate')

        if ((cpf.length === 14 && cpfCheck(cpf))) {
            cpfMsg.style.display = 'none'
            return true
        }

        cpfMsg.style.display = 'block'
        return false
    }, [cpf])

    const handleCpfChange = (e) => {
        let value = e.target.value

        setCpf(cpfMask(value))
    }

    const handleCheckboxClick = e => {
        let btnSubmit = document.getElementById('btn-modal-submit')
        if (e.target.checked) {
            btnSubmit.disabled = false
            return
        }

        btnSubmit.disabled = true
        return
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!handleCpfInvalid()) return

        try {
            const formData = new FormData(form.current)
            formData.append("optin", "True")
            fetch('/', {
                method: 'POST',
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: new URLSearchParams(formData).toString()
            }).then(() => console.log('Form successfully submitted')).catch((error) =>
                console.error(error))

            window.open(PDF_FILE_URL, '_blank').focus()
            window.dataLayer.push({'event': 'sendForm'});
            // onClickClose()
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (cpf.length === 14) {
            handleCpfInvalid()
        }
    }, [handleCpfInvalid, cpf])

    return (
        <Styled.Modal ref={modal} id="modal-download">
            <Styled.Content>
                <ButtonClose className="btn-modal-close" handleOnClick={handleCloseModal} />
                <Styled.ContentTitle
                    colorName="--color-dark"
                    lineHeight="2.5rem"
                    fontSize="2.25rem"
                    dangerouslySetInnerHTML={{ __html: data.title }}
                />
                <Styled.ContentText
                    dangerouslySetInnerHTML={{ __html: data.text }}
                />
                <Styled.Form ref={form} id="download" onSubmit={handleSubmit}>
                    <Styled.FormRow>
                        <Styled.FormCol>
                            <Styled.Input name="nome" placeholder={data.name} required />
                        </Styled.FormCol>
                        <Styled.FormCol>
                            <Styled.Input name="sobrenome" placeholder={data.lastName} required />
                        </Styled.FormCol>
                    </Styled.FormRow>
                    <Styled.FormRow>
                        <Styled.FormCol>
                            <Styled.Input name="email" placeholder={data.email} required />
                        </Styled.FormCol>
                    </Styled.FormRow>
                    <Styled.FormRow>
                        <Styled.FormCol>
                            <Styled.Input
                                id="cpf"
                                name="cpf"
                                value={cpf}
                                placeholder={data.cpf}
                                maxLength="14"
                                onChange={handleCpfChange}
                                required
                            />
                            <span className="cpf-validate">* CPF inválido</span>
                        </Styled.FormCol>
                    </Styled.FormRow>
                    <Styled.FormRow>
                        <Styled.FormCol>
                            <Styled.Input name="empresa" placeholder={data.company} />
                        </Styled.FormCol>
                        <Styled.FormCol>
                            <Styled.Input name="cargo" placeholder={data.role} />
                        </Styled.FormCol>
                    </Styled.FormRow>
                    <Styled.FormRow className="p-2x">
                        <Styled.FormCol>
                            <Checkbox
                                name="termos"
                                value="True"
                                label={data.checkbox}
                                handleCheckboxClick={handleCheckboxClick}
                            />
                        </Styled.FormCol>
                    </Styled.FormRow>
                    <Styled.FormRow className="p-2x">
                        <Styled.FormCol>
                            <Styled.FormButton
                                id="btn-modal-submit"
                                type="submit"
                                backgroundColor="--color-1"
                                dangerouslySetInnerHTML={{ __html: data.button }}
                                disabled
                            />
                        </Styled.FormCol>
                    </Styled.FormRow>
                </Styled.Form>
            </Styled.Content>
        </Styled.Modal>
    )
}

export default Modal
