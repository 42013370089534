import styled from 'styled-components'

// COMPONENTS
import { Section } from '../../../components/section/Section.styles'
import { Wrapper } from '../../../components/wrapper/Wrapper.styles'
import { Title } from '../../../components/title/Title.styles'
import { Text } from '../../../components/text/Text.styles'

export const SectionContainer = styled(Section)`
    padding-top: 130px;
    @media screen and (max-width: 1118px) {
        min-height: auto;
    }
`;

export const SectionWrapper = styled(Wrapper)`
    justify-content: center;
    align-items: flex-start;
`;

export const Content = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    min-height: 600px;
    margin: 0 7rem;
    @media screen and (max-width: 1118px) {
        align-items: flex-start;
        margin: 0 0 1.875rem;
    }
    &::before {
        display: block;
        position: absolute;
        right: 0;
        content: "";
        height: 599px;
        width: 427px;
        background-image: ${({ backgroundImage }) => backgroundImage ? 'url(' + backgroundImage + ')' : 'none'};
        background-repeat: no-repeat;
        background-size: cover;
        @media screen and (max-width: 1118px) {
            left: unset;
            right: 0;
            bottom: 0; 
            height: 299.07px;
            width: 178.66px;
        }
    }
`;

export const ContentRank = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 2.5rem;
    width: 7rem;
    height: 100%;
    @media screen and (max-width: 1118px) {
        display: none;
    }
`;

export const RankPosition = styled.div`
    display: flex;
    align-items: flex-end;
    svg {
        margin-right: 20px;
        margin-bottom: 5px;
    }
    a {
        text-decoration: none;
        outline: none;
        color: var(--color-dark);
    }
`;

export const RankPositionNumber = styled.div`
    font-family: DystopianBold;
    font-size: 28px;
    line-height: 34px;
    opacity: 0.2;
    cursor: pointer;
    &.actual {
        opacity: 1;
    }
`;

export const ContentTitle = styled(Text)`
    display: flex;
    align-items: center;
    font-family: PoppinsMedium;
    font-size: 1.5rem;
    width: 100%;
    margin-bottom: 6.25rem;
    z-index: 1;
    @media screen and (max-width: 1118px) {
        max-width: 130px;
        font-size: 0.938rem;
        line-height: 1.063rem;
    }
    span {
        font-family: DystopianBold;
        font-size: 2.5rem;
        line-height: 3rem;
        margin-right: 28px;
        @media screen and (max-width: 1118px) {
            font-size: 1.375rem;
            line-height: 1.7rem;
        }
    }
`;

export const ContentBody = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 1;
`;

export const BrandLogo = styled.div`
    margin-bottom: 3.125rem;
    @media screen and (max-width: 1118px) {
        margin-bottom: 1.875rem;
        svg {
            width: 87px;
            height: auto;
        }
    }
`;

export const BrandInfo = styled.div`
    border-top: 2px solid var(--color-dark);
    max-width: 780px;
    padding-top: 45px;
    display: flex;
    gap: 100px;
    @media screen and (max-width: 1118px) {
        padding-top: 20px;
        flex-direction: column;
        gap: 20px;
    }
`;

export const BrandNumber = styled(Title)`
    font-size: 5rem;
    line-height: 6rem;
    color: ${({ colorValue }) => colorValue ? colorValue : 'var(--color-dark)'};
    margin-top: -22px;
    @media screen and (max-width: 1118px) {
        font-size: 2.25rem;
        line-height: 2.688rem;
        margin-top: 0;
    }
`;

export const BrandInfoBox = styled.div`
    display: flex;
    flex-direction: column;
`;

export const BrandInfoLabel = styled(Text)`
    @media screen and (max-width: 1118px) {
        font-size: 0.625rem;
        line-height: 1rem;
    }
`;

export const BrandInfoValue = styled.div`
    font-family: DystopianBold;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: bold;
    line-height: 3rem;
    color: ${({ colorValue }) => colorValue ? colorValue : 'var(--color-dark)'};
    @media screen and (max-width: 1118px) {
        font-size: 1.75rem;
        line-height: 2.125rem;
    }
`;