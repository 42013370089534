import React from 'react'

// STYLES
import * as Styled from './About.styles'

// DATA
import data from '../../../data/home/about'

const About = () => {
    return (
        <Styled.SectionContainer id="about">
            <Styled.SectionWrapper>
                <Styled.Content>
                    <Styled.ContentBody>
                        <Styled.Date>
                            10 00 11
                        </Styled.Date>
                        <Styled.ContentTitle dangerouslySetInnerHTML={{ __html: data.title }} />
                        <Styled.ContentTextWrapper>
                            <span>*</span>
                            <Styled.ContentText dangerouslySetInnerHTML={{ __html: data.text }} />
                        </Styled.ContentTextWrapper>
                    </Styled.ContentBody>
                </Styled.Content>
            </Styled.SectionWrapper>
        </Styled.SectionContainer>
    );
}

export default About
