import React from 'react'
import { Helmet } from 'react-helmet-async'

// MODULES
import About from '../modules/home/about/About'
import Comparison from '../modules/home/comparison/Comparison'
import Footer from '../modules/footer/Footer'
import Header from '../modules/header/Header'
import Hero from '../modules/home/hero/Hero'
import Highlights from '../modules/home/highlights/Highlights'
import Methodology from '../modules/home/methodology/Methodology'
// import Score from '../modules/home/score/Score'
import Study from '../modules/home/study/Study'
import TopFive from '../modules/home/topFive/TopFive'
import Modal from '../modules/modal/Modal'

const Home = () => {
    return (
        <>
            <Helmet>
                <title>Future‑proof Score</title>
                <meta name="description" content="Estudo das marcas mais preparadas para o futuro no cenário brasileiro." />
            </Helmet>
            <div id="home">
                <Header />
                <Hero />
                <About />
                <TopFive />
                <Highlights />
                <Study />
                <Comparison />
                {/* <Score /> */}
                <Methodology />
                <Footer />
                <Modal />
            </div>
        </>
    )
}

export default Home