import styled from 'styled-components'

// COMPONENTS
import { Title } from '../../components/title/Title.styles'
import { Text } from '../../components/text/Text.styles'
import { Button } from '../../components/button/Button.styles'

export const Modal = styled.div`
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
    &.modal-active {
        display: flex;
    }
`

export const Content = styled.div`
    position: relative;
    background-color: var(--color-light);
    padding: 4.125rem 8rem 4.125rem 3.75rem;
    width: 100%;
    max-height: 100%;
    max-width: 696px;
    overflow-y: auto;
    @media screen and (max-width: 1118px) {
        height: 100vh;
        padding: 4.125rem 5rem 4.125rem 1.875rem;
    }
    .btn-modal-close {
        position: absolute;
        right: 30px;
        top: 30px;
        div {
            background-color: var(--color-2);
        }
    }
`

export const ContentTitle = styled(Title)`
    @media screen and (max-width: 1118px) {
        font-size: 0.938rem;
        line-height: 1.375rem;
    }
`

export const ContentText = styled(Text)`
    margin-top: 20px;
    @media screen and (max-width: 1118px) {
        font-size: 0.75rem;
        line-height: 1rem;
    }
`

export const Form = styled.form`
    position: relative;
    margin-top: 40px;
    .cpf-validate {
        display: none;
        margin-top: 5px;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 8px;
        line-height: 100%;
        color: var(--color-3);
    }
`

export const FormRow = styled.div`
    display: flex;
    gap: 20px;
    width: 100%;
    @media screen and (max-width: 1118px) {
        flex-direction: column;
        gap: 20px;
    }
    &:not(:first-of-type) {
        margin-top: 20px;
        &.p-2x {
            margin-top: 40px;
        }
    }
`

export const FormCol = styled.div`
    width: 100%;
`

export const Input = styled.input`
    width: 100%;
    box-sizing: border-box;
    outline: none;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: #979797;
    margin: 0;
    padding: 10px;
    border: 1px solid var(--color-dark);
    border-radius: 0;
    outline: none;
`

export const FormButton = styled(Button)`
    @media screen and (max-width: 1118px) {
        font-size: 0.75rem;
        line-height: 1rem;
    }
`;