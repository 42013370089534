import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { readString } from 'react-papaparse'

// MODULES
import BrandInfo from '../brandInfo/BrandInfo'
import BrandAbout from '../brandAbout/BrandAbout'

// DATA
import brands from '../../../data/brands'
import brandsCSV from '../../../data/brands.csv'

const BrandContent = () => {
    const [CSVLoaded, setCSVLoaded] = useState(false)
    const [CSVData, setCSVData] = useState(false)
    const { brandSlug } = useParams()
    const brandActual = brands.find(brand => brand.slug === brandSlug)
    const positions = brands.map((item, index) => {
        return {
            slug: item.slug,
            position: item.position
        }
    })

    const loadCSVData = useCallback(() => {
        const papaConfig = {
            complete: (results, file) => {
                const rows = results.data
                rows.shift()

                const result = rows.map((row, index) => {
                    return {
                        position: parseInt(row[0]),
                        name: row[1],
                        // score: parseFloat(row[2]),
                        data: [
                            parseFloat(row[3]),
                            parseFloat(row[4]),
                            parseFloat(row[5]),
                            parseFloat(row[6]),
                            parseFloat(row[7]),
                            parseFloat(row[8])
                        ]
                    }
                }).filter((row) => {
                    return row.position === brandActual.position
                })

                setCSVLoaded(true)
                setCSVData({ ...brandActual, ...result[0] })
            },
            download: true,
            error: (error, file) => {
                console.log('Error while parsing:', error, file);
            },
        }
        readString(brandsCSV, papaConfig)
    }, [brandActual, setCSVLoaded, setCSVData])

    useEffect(() => {
        if (CSVLoaded) return

        loadCSVData()
    }, [CSVLoaded, loadCSVData])

    return CSVData &&
        <>
            <BrandInfo brand={CSVData} positions={positions} />
            <BrandAbout brand={CSVData} />

        </>

}
export default BrandContent