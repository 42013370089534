const methodology = {
    title: "metodologia",
    slides: [
        "O Future-proof Score se construiu em um processo faseado, que parte de data analytics no ambiente digital (sites, portais, plataformas, redes sociais, etc) para mapear as marcas atuantes no mercado brasileiro, e aplica filtros para identificar as que estão fazendo a diferença e construindo o futuro hoje.",
        "Para estabelecer os filtros e critérios de análise, foram considerados os dois eixos da metodologia Future-proof: o eixo de propósito, que avalia visão de futuro e estratégia de negócio; e o eixo de experiência, que considera a manifestação e atuação da marca no dia a dia.",
        "Após a análise quantitativa digital, 120 marcas passaram para a próxima etapa: a análise especialista de marca. Combinando as diferentes lentes, foi realizada por diversos profissionais de branding que avaliaram fatores como consistência, coerência e relevância, além de um olhar para o contexto geral de comportamento, tendências e de movimentos de concorrência.",
        "Todos os dados brutos, análises e indicadores viraram pontuações de 0 a 1000 para cada dimensão analisada, que consideram não só a marca avaliada, mas também os resultados das demais marcas, tendo 500 como a média dos resultados observados em cada indicador.",
    ]
};

export default methodology