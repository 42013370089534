import React, { useCallback, useEffect, useState } from "react";
import { readString } from 'react-papaparse'

// MODULES
import BrandChart from '../../../components/brandChart/BrandChart'
import Select from "../../../components/select/Select";

// STYLES
import * as Styled from './ComparisonContent.styles'

// DATA
import data from '../../../data/comparison/info'
import brandsCSV from '../../../data/brands.csv'

const ComparisonContent = () => {
    const [CSVLoaded, setCSVLoaded] = useState(false);
    const [CSVData, setCSVData] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [selectOptions, setSelectOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedCompareOption, setSelectedCompareOption] = useState('');

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value)
        window.dataLayer.push({'event': 'selectBrand', 'label': event.target.value});
    }

    const handleCompareChange = (event) => {
        setSelectedCompareOption(event.target.value)
        window.dataLayer.push({'event': 'compareBrand', 'label': event.target.value});
    }

    const loadCSVData = useCallback(() => {
        const papaConfig = {
            complete: (results, file) => {
                const rows = results.data
                rows.shift()

                let result = rows.map((row, index) => {
                    let name = row[1]
                    selectOptions.push(name)
                    if (index === 0) {
                        setSelectedOption(name)
                    }
                    if (index === 1) {
                        setSelectedCompareOption(name)
                    }
                    return {
                        position: parseInt(row[0]),
                        name: name,
                        score: parseFloat(row[2]),
                        data: [
                            parseFloat(row[3]),
                            parseFloat(row[4]),
                            parseFloat(row[5]),
                            parseFloat(row[6]),
                            parseFloat(row[7]),
                            parseFloat(row[8])
                        ]
                    }
                })

                const total = rows.length

                const sum = rows.reduce((acc, next) => {
                    return [
                        acc[0] + parseFloat(next[3]),
                        acc[1] + parseFloat(next[4]),
                        acc[2] + parseFloat(next[5]),
                        acc[3] + parseFloat(next[6]),
                        acc[4] + parseFloat(next[7]),
                        acc[5] + parseFloat(next[8])
                    ]
                }, Array(6).fill(0))

                const avg = sum.map((v, i) => {
                    return v / total
                })

                selectOptions.push('MÉDIA')

                result.push({
                    name: "MÉDIA",
                    data: avg
                })

                setSelectOptions(selectOptions)
                setCSVLoaded(true)
                setCSVData(result)
            },
            download: true,
            error: (error, file) => {
                console.log('Error while parsing:', error, file);
            },
        }
        readString(brandsCSV, papaConfig)
    }, [setCSVLoaded, setCSVData, setSelectOptions, selectOptions, setSelectedOption, setSelectedCompareOption])

    useEffect(() => {
        if (CSVLoaded) return

        loadCSVData()
    }, [CSVLoaded, loadCSVData])

    useEffect(() => {
        if (CSVData) {
            const result = CSVData.filter((row) => {
                return row.name === selectedOption || row.name === selectedCompareOption
            })
            setChartData(result)
        }
    }, [CSVData, setChartData, selectedOption, selectedCompareOption])

    return (
        <Styled.SectionContainer id="comparison">
            <Styled.SectionWrapper>
                <Styled.Content>
                    <Styled.ContentBody>
                        <Styled.ContentTextWrapper>
                            <Styled.ContentTitle
                                dangerouslySetInnerHTML={{ __html: data.title }}
                            />
                            <Styled.ContentText
                                dangerouslySetInnerHTML={{ __html: data.text }}
                            />
                            <Select
                                label={data.select}
                                options={selectOptions.filter((option) => option !== selectedCompareOption)}
                                selected={selectedOption}
                                handleChange={handleSelectChange}
                            />
                            <Select
                                label={data.compare}
                                options={selectOptions.filter((option) => option !== selectedOption)}
                                selected={selectedCompareOption}
                                handleChange={handleCompareChange}
                            />
                        </Styled.ContentTextWrapper>

                        <Styled.ContentChartWrapper>
                            <Styled.ContentChartTitle
                                dangerouslySetInnerHTML={{ __html: `<span>${selectedOption}</span><br />vs <span>${selectedCompareOption}</span>` }}
                            />
                            <Styled.ContentChart>
                                <BrandChart
                                    series={chartData}
                                />
                            </Styled.ContentChart>
                        </Styled.ContentChartWrapper>
                    </Styled.ContentBody>
                </Styled.Content>
            </Styled.SectionWrapper>
        </Styled.SectionContainer>
    );
}

export default ComparisonContent