import styled from 'styled-components'

export const Wrapper = styled.div`
    position: relative;
    display: flex;
    max-width: 1440px;
    width: 100%;
    height: 100%;
    flex: 1;
    padding: var(--padding);
`;