import React from 'react'

import ButtonModal from '../../../components/buttonModal/ButtonModal'

// ASSETS
import backgroundContent from '../../../assets/images/background-study.jpg'
import { ReactComponent as IconArrowRight } from '../../../assets/images/icon-arrow-right-black.svg'

// STYLES
import * as Styled from './Study.styles'

// DATA
import data from '../../../data/home/study'

const Study = () => {
    return (
        <Styled.SectionContainer id="study">
            <Styled.SectionContainerMask>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </Styled.SectionContainerMask>
            <Styled.SectionWrapper>
                <Styled.Content backgroundImage={backgroundContent}>
                    <Styled.ContentBody>
                        <Styled.ContentTitle
                            lineHeight="4.5rem"
                            fontSize="3.75rem"
                            dangerouslySetInnerHTML={{ __html: data.title }}
                        />
                        <Styled.ContentTextWrapper>
                            <Styled.ContentText dangerouslySetInnerHTML={{ __html: data.text }} />
                            <IconArrowRight />
                            <Styled.ButtonDesc dangerouslySetInnerHTML={{ __html: data.buttonDesc }} />
                            <ButtonModal
                                backgroundColor="--color-5"
                                className="btn-modal"
                            >
                                {data.button}
                            </ButtonModal>

                        </Styled.ContentTextWrapper>
                    </Styled.ContentBody>
                </Styled.Content>
            </Styled.SectionWrapper>
        </Styled.SectionContainer>
    );
}

export default Study
