import styled from 'styled-components'

// COMPONENTS
import { Section } from '../../../components/section/Section.styles'
import { Wrapper } from '../../../components/wrapper/Wrapper.styles'
import { Title } from '../../../components/title/Title.styles'
import { Text } from '../../../components/text/Text.styles'

export const SectionContainer = styled(Section)`
    background-color: var(--color-dark);
    color: var(--color-ligth);
    background-image: ${({ backgroundImage }) => backgroundImage ? 'url(' + backgroundImage + ')' : 'none'};
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 130px;
`;

export const SectionBackground = styled.video`
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
`;

export const SectionWrapper = styled(Wrapper)`
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    color: var(--color-warm);
    margin-top: -130px;
`;

export const ContentTitle = styled(Title)`
    max-width: 850px;
    margin-bottom: 2.25rem;
    @media screen and (max-width: 1118px) {
        font-size: 3.125rem;
        line-height: 3.125rem;
    }
`;

export const ContentText = styled(Text)`
    max-width: 370px;
`;

export const ContentFooter = styled.div`
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--padding);
    @media screen and (max-width: 1118px) {
        flex-direction: column;
        align-items: flex-start;
    }

`;

export const LogosWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 34px;
    @media screen and (max-width: 1118px) {
        gap: 23px;
        margin-bottom: 30px;
        svg {
            &:nth-of-type(1) {
                max-width: 125px;
                max-height: 37px;
            }
            &:nth-of-type(2) {
                max-width: 79px;
                max-height: 23px;
            }
        }
    }

`;

export const IconDownloadWrapper = styled.div`
    position: relative;
    width: 70px;
    height: 70px;
    @media screen and (max-width: 1118px) {
        width: 40px;
        height: 40px;
    }
    #icon-download {
        position: fixed;
        z-index: 9;
        text-decoration: none;
        outline: none;
        background-color: transparent;
        border: none;
        padding: 0;
        @media screen and (max-width: 1118px) {
            position: relative;
            svg {
                width: 40px;
                height: 40px;
            }
        }
        &.icon-download-footer {
            bottom: 150px;
        }
    }
`;
