import styled from 'styled-components'

// COMPONENTS
import { Section } from '../../../components/section/Section.styles'
import { Wrapper } from '../../../components/wrapper/Wrapper.styles'
import { Title } from '../../../components/title/Title.styles'
import { Text } from '../../../components/text/Text.styles'

export const SectionContainer = styled(Section)``;

export const SectionWrapper = styled(Wrapper)`
    justify-content: center;
    align-items: center;
    padding: 10.25rem 3.75rem;
    @media screen and (max-width: 1118px) {
        padding: var(--padding);
    }
`;

export const Content = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: var(--color-light);
    max-width: 1118px;
    width: 100%;
    height: 100%;
`;

export const ContentBody = styled.div`
    max-width: 907px;
    width: 80%;
    @media screen and (max-width: 1118px) {
        width: 100%;
    }
`;

export const ContentTitle = styled(Title)`
    font-size: 4.5rem;
    width: 100%;
    margin-bottom: 4.5rem;
    @media screen and (max-width: 1118px) {
        font-size: 2.125rem;
        line-height: 2.563rem;
        padding-left: 50px;
        margin-top: 60px;
    }
`;

export const ContentTextWrapper = styled.div`
    max-width: 430px;
    margin-left: 40%;
    @media screen and (max-width: 1118px) {
        max-width: 100%;
        margin-left: 0;
    }
    span {
        font-family: Dystopian;
        font-style: normal;
        font-weight: bold;
        font-size: 2.5rem;
        transform: rotate(45deg) translateY(15px);
        display: inline-block;
        color: var(--color-dark);
    }
`;

export const ContentText = styled(Text)`
    margin-top: 1.5rem;
`;

export const Date = styled.div`
    font-family: PoppinsMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: var(--color-2);
    white-space: nowrap;
    transform: rotate(90deg) translateX(28px);
    position: absolute;
    left: 0;
`;