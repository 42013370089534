import styled from 'styled-components'

// COMPONENTS
import { Section } from '../../../components/section/Section.styles'
import { Wrapper } from '../../../components/wrapper/Wrapper.styles'
import { Title } from '../../../components/title/Title.styles'
import { Text } from '../../../components/text/Text.styles'

export const SectionContainer = styled(Section)`
    background-color: var(--color-warn);
`;

export const SectionWrapper = styled(Wrapper)`
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8.125rem 3.75rem;
    @media screen and (max-width: 1118px) {
        padding: var(--padding);
    }
    &::before {
        display: block;
        position: absolute;
        top: 38%;
        left: 0;
        content: "";
        width: 708px;
        height: 386px;
        background-image: ${({ backgroundImage }) => backgroundImage ? 'url(' + backgroundImage + ')' : 'none'};
        background-repeat: no-repeat;
        background-size: cover;
        @media screen and (max-width: 1118px) {
            width: 368px;
            height: 205px;
            position: relative;
            margin-bottom: 50px;
        }
    }
`;

export const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: 1118px;
    width: 100%;
    height: 100%;
    color: var(--color-light);
`;

export const ContentHeader = styled.div`
    display: flex;
    gap: 1.5rem;
    color: var(--color-light);
    width: 100%;
    margin-bottom: 8rem;
    @media screen and (max-width: 1118px) {
        margin-bottom: 4rem;
    }
`;

export const ContentTitle = styled(Title)`
    @media screen and (max-width: 1118px) {
        font-size: 2.5rem;
        line-height: 3rem;
    }
`;

export const ContentText = styled(Text)`
    width: 100%;
    font-size: 1.125rem;
    line-height: 1.75rem;
`;

export const ContentBody = styled.div`
    width: 40%;
    z-index: 1;
    @media screen and (max-width: 1118px) {
        width: 100%;
    }
`;

export const Arrows = styled.div`
    display: flex;
    flex-direction: column;
    flex: 2;
    align-items: center;
    gap: 3.125rem;
    @media screen and (max-width: 1118px) {
        gap: 1.25rem;
        align-items: flex-end;
    }
    svg {
        width: 1.326rem;
        height: 1.326rem;
        @media screen and (max-width: 1118px) {
            width: 0.584rem;
            height: 0.584rem;
        }
        path {
            stroke: var(--color-3);
        }
    }
`;