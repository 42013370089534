import React from 'react'
import Chart from 'react-apexcharts'

const BrandChart = (props) => {
    const { colors, series } = props

    const data = {
        series,
        options: {
            chart: {
                type: 'radar',
                dropShadow: {
                    enabled: true,
                    blur: 1,
                    left: 1,
                    top: 1
                },
                toolbar: {
                    show: false
                },

                sparkline: {
                    enabled: false,
                }
            },
            colors,
            fill: {
                opacity: 0.1
            },
            markers: {
                size: 4,
                hover: {
                    size: 8
                }
            },
            plotOptions: {
                radar: {
                    size: undefined,
                    offsetX: 0,
                    offsetY: 0,
                    polygons: {
                        strokeColors: '#e8e8e8',
                        strokeWidth: 1,
                        connectorColors: 'transparent',
                        fill: {
                            colors: undefined
                        }
                    }
                }
            },
            stroke: {
                width: 2
            },
            title: {
                text: undefined
            },
            xaxis: {
                categories: [
                    ['Tem uma visão', 'clara de futuro'],
                    ['Redefine a', 'categoria'],
                    ['Entrega valor', 'sustentável'],
                    ['Oferece uma experiência', 'engajadora nos pontos', 'de contato'],
                    ['Cria um vínculo', 'emocional'],
                    ['Melhora a vida', 'das pessoas']
                ],
                labels: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        colors: ['#000000', '#000000', '#000000', '#000000', '#000000', '#000000'],
                        fontSize: '12px',
                        fontFamily: 'Poppins',
                        fontWeight: 400,
                        cssClass: 'apexcharts-xaxis-label',
                    },
                },
            },
            yaxis: {
                show: false,
            },
        }
    }

    return (
        <Chart options={data.options} series={data.series} responsive={data.responsive} type="radar" width="100%" height="100%" />
    );
}

export default BrandChart
