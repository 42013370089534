import React from 'react'

// STYLES
import * as Styled from './ButtonModal.styles'

const ButtonModal = (props) => {
    const { backgroundColor, children, className, colorName, id } = props
    const classes = className ? `btn-modal-open ${className}` : 'btn-modal-open'

    const handleOpenModal = () => {
        document.getElementById('modal-download').classList.toggle('modal-active')
    }

    return (
        <Styled.Button id={id} className={classes} colorName={colorName} backgroundColor={backgroundColor} onClick={handleOpenModal}>
            {children}
        </Styled.Button>
    );
}

export default ButtonModal
