import React from 'react'
import { HashLink as Link } from 'react-router-hash-link'

// STYLES
import * as Styled from './Menu.styles'

// DATA
import menu from '../../data/menu'
import ButtonBurger from '../buttonBurger/ButtonBurger';

const Menu = (props) => {
    const { open, onToogleMenu } = props
    return (
        <Styled.Menu>
            <ButtonBurger open={open} onClickButton={onToogleMenu} className="open-menu" />
            <Styled.Nav open={open} onClick={onToogleMenu} >
                <Styled.Wrapper>
                    {menu.map((item, index) => {
                        return (
                            <Link
                                key={index}
                                to={item.link}
                                dangerouslySetInnerHTML={{ __html: item.title }}
                                className="menu-item"
                            />
                        )
                    })}
                </Styled.Wrapper>
            </Styled.Nav>
        </Styled.Menu>
    );
}

export default Menu
